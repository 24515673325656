/* img{
    width: 90px;
    height: 70px;
} */

.pt-card{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: 1px solid rgb(173, 173, 173);
    border-right-width: 1rem;
    cursor: pointer;
    
    /* border-image: linear-gradient(#000000, #b9b9b9, black) 30;
    border-width: 1px;
    border-style: ; */
    border-radius: 0.5rem;
    /* padding: 0.25rem; */
    :hover{
        background-color: rgba(0, 0, 0, 0.493);
        
    }
}

.pt-card.active{
    border-right-color: var(--nav-logo-icon);
}

/* Overlay styles */
.pt-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.199); /* Faded green color with transparency */
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  
  /* Hover effect */
  .pt-card:hover::before {
    opacity: 1;
  }

.pt-logo{
    /* height: 60px; */
    width:150px;
    max-height: 80px;
    justify-content: center;
}

.pt-stats{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    padding: 0.25rem 0 0.25rem 0;
    > div{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.15rem;
    }
}

.pt-info-icon{
    fill: var(--nav-background);
    position: absolute;
    margin: 0.25rem 0.25rem 0.5rem 0.5rem;
    left: 0;
    bottom: 0;
}

.pt-info-icon:hover{
    cursor: pointer;
    background: none;
    transform: scale(1.7);
}