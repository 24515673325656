

.nav-main{
    background-color: var(--nav-background);
}

.nav-text{
    color: var(--nav-logo-text);
    font-size: 1.5rem;
    font-weight: 700;
    margin-left: 10px;
    user-select: none;
}

.tw-logo{
    width: 30px;
    height: 30px;
}

.tw-logo path{
    fill: var(--nav-logo-icon);
}

.nav-bt-help{
    background: var(--nav-logo-icon);
    border-color: var(--nav-background);
    color: var(--nav-background);
}

.nav-bt-help:hover{
    background: var(--nav-background) !important;
    border-color: var(--nav-logo-icon) !important;
    color: var(--nav-logo-icon) !important;
}

.nav-bt-share{
    margin-right: 1rem;
    background: var(--nav-background);
    border-color: var(--nav-logo-icon);
    color: var(--nav-logo-icon);
}

.nav-bt-share:hover{
    background: var(--nav-logo-icon) !important;
    border-color: var(--nav-background) !important;
    color: var(--nav-background) !important;
}