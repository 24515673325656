.table-eng-popup{
    border: 1px solid black;
    border-radius: 2rem;
    padding: 0.25rem;
    width: 100px;
    cursor: pointer;
}

.table-eng-popup:hover{
    transform: scale(1.1);
}

.p-dialog-content{
    display: flex !important;
    flex-direction: row;
}

.dialog-tech-pop > .p-dialog-header{
    background: var(--nav-background) !important;
    color: var(--nav-logo-text) !important;
}
