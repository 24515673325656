.container-threat-capabilities{
    display: flex;
    flex-direction: column;
    /* overflow-y: auto; */
    min-width: 250px;
    flex-grow: 1;
    height: 100%;
    border: 1px solid var(--nav-background);
    /* padding: 0.5rem; */
}

.product-cards{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0.5rem;
    gap: 0.5rem;
}

.product-controls{
    display: flex;
    flex-direction: column;
    width: 100%;
}