

.threatGroup {
    
    .did-number{
        fill: rgb(255, 255, 255);
        font-size: 150%;
    }

    rect {
        stroke-width: 1;
        stroke:rgb(0,0,0);
        transition: fill 0.5s;
    }

    rect:hover{
        fill: var(--svg-hover);
        stroke-width: 0.25rem;
        stroke: var(--nav-background);
    }

    text {
        text-anchor: middle;
        alignment-baseline: middle; // still works even though it's unknown
    }

    .text-normal {
        font: 20px sans-serif;
    }

    .text-shrink {
        font: 15px sans-serif;
    }

    .text-tactic {
        font: 20px "Roboto-Regular", sans-serif;
        font-weight: bolder;
        text-anchor: middle;
        alignment-baseline: middle; // still works even though it's unknown
        fill: var(--nav-background);
    }

    .text-tech-count{
        font: 18px "Roboto-Regular", sans-serif;
        font-weight: bold;
        font-style: italic;
        text-anchor: middle;
        alignment-baseline: middle;
        fill: var(--nav-background);
    }
}

.outofscope{
    stroke: black;
    stroke-width: 2;
    stroke-linecap:round;
}

.dialog-technique-popup td{
    // padding: 0.25rem 0.25rem !important;
    // vertical-align: middle !important;
}




.no-click {
    pointer-events: none;
}

.legend {
    border-style: solid;
}

.display-attack-col{
    display: flex;
    flex-direction: column;
    // left: 0;
    
    position: absolute;
    justify-self: flex-start;

    // justify-self: flex-start;
    // padding: 0.5rem;
    margin: 0.5rem;
    z-index: 2;
}

.display-attack-row1{
    display: flex;
    flex-direction: row;

    .controls{
        display: flex;
        flex-direction: column;
        // padding-left: 0.5rem;
    }

    .icon{
        display: flex;
        flex-grow: 1;
        width: 2rem;
        height: 2rem;
        background:rgba(255, 255, 255, 0.5);
        border: 1px solid black;
    }

    .icon:hover{
        cursor: pointer;
        transform: scale(1.2);
        background: white;
    }
}

.display-attack-row2{
    display: flex;
    flex-direction: row;
    // left: 0;
    padding-top: 0.5rem;

    .analytics{
        // padding: 0.5rem;
    }

    .popout{
        width: 2rem;
        height: 2rem;
        background:rgba(255, 255, 255, 0.5);
        border-radius: 8px;
    }

    .popout:hover{
        cursor: pointer;
        transform: scale(1.2);
        background: white;
    }
}

.display-attack-row3{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: baseline;
    width: fit-content;
    align-items: center;
    padding-top: 0.5rem;
    

    .filters{
        // padding: 0.5rem;
        justify-content: left;
    }

    .popout{
        width: 2rem;
        height: 2rem;
        background:rgba(255, 255, 255, 0.5);
        border: 1px solid black;
    }

    .popout:hover{
        cursor: pointer;
        transform: scale(1.2);
        background: white;
    }
}
  
.display-attack-item{
    // display: flex;
    border: 1px solid black;
    // flex-flow:row;
    max-width: 3rem;
    max-height: 5rem;
    background: rgba(255, 255, 255, 0.5);
}
  
.display-attack-item:hover{
    transform: scale(1.5);
    background: white;
    cursor: pointer;
}
