.svg-weight-icon > svg{
    width: 4rem;
    height: 4rem;
    cursor: none;
}

.logo-dialog{
    margin: 0.5rem;
    height: 1.5rem;
    border: 2px solid var(--nav-background);
    border-radius: 0.25rem;
    padding: 0.25rem;
  }

  .logo-dialog:hover{
    transform: scale(1.3);
  }