.svg-csf-wheel{
    width: 7rem;
    height: 7rem;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0.25rem;
}

.csf-wheel-elm{
    opacity: 0.4;
}

.csf-wheel-elm.active{
    opacity: 1;
}

.csf-wheel-elm:hover{
    opacity: 1;
    stroke: black;
    stroke-width: 0.25rem;
    cursor: pointer;
}

.csf-wheel-main > path {
    pointer-events: none;
    /* user-select: none; */
}