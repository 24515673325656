.eng-criteria-text{
    position: relative;
    word-wrap: break-word;
    border: 1px solid black;
    border-radius: 6px;
    padding: 0.25rem;
    /* text-align: center; */
}

/* Overlay styles */
.eng-criteria-text::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%;
    background-color: rgba(0, 0, 0, 0.199); /* Faded green color with transparency */
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  
  /* Hover effect */
  .eng-criteria-text:hover::before {
    opacity: 1;
    cursor: pointer;
  }

  .react-transform-wrapper{
    display: flex !important;
    height: 100%;
  }

  .p-galleria-item-wrapper, .p-galleria-content{
    height: 100% !important;
    width: 100%;
  }
/* 
  .p-galleria-caption{
    display: none;
  } */

  .eng-info-icon{
    position: absolute;
    bottom: 2rem;
    right: 1.5rem;
    margin: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    color: blue;
    z-index: 9;
  }

  .p-galleria-item-next, .p-galleria-item-prev{
    color: grey !important;
    z-index: 9;
  }

  .p-galleria-close.p-link{
    color: grey !important;
    z-index: 9;
  }

.eng-fs-toggle{
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10009;
}

.eng-criteria-text.active{
  background: rgb(213 213 213);
  margin: 0.5rem 0 0.5rem 1rem;
}