:root{
  --nav-background: #2E403F;
  --nav-logo-text: #77CD88;
  --nav-logo-icon: #77CD88;
  --color-csf-high : #31c44f;
  --color-csf-med : #77cd88;
  --color-csf-low : #77cd8885;
  --color-csf-none : #ffffff00;

  --svg-hover : #f89c1c6e;

  --color-csf-protect: #835294;
  --color-csf-detect: #F89D1C;
}


.App {
  text-align: center;
}



.table-pdr{
  color: black;
  display: flex;
  justify-content: center;
  margin: auto;
}

/* td:has(.table-eng-popup){

} */

.table-pdr.high{
  background: var(--color-csf-high);
}

.table-pdr.med{
  background: var(--color-csf-med);
}

.table-pdr.low{
  background: var(--color-csf-low);
}

.table-pdr.none{
  background: var(--color-csf-none);
}
